<template>
    <router-view />
</template>

<script setup>
</script>


<style>
</style>
