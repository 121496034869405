<template>
  <div class="content">
    <div class="login-wrapper">
      <div class="left-img">
        <div class="glass">
          <div class="tips">
            <div class="title">即时聊天室</div>
            <h1>欢迎加入我们的聊天室！</h1>
            <span
              >这里是一个互动、开放、自由的空间，你可以畅所欲言，与世界各地的朋友交流。无论您是学生、职场新人、资深工作者还是退休人士，都可以在这里找到属于自己的一片天地。</span
            >
            <span
              >本聊天室支持无注册聊天,用户可以选择公共模式，与陌生人于公共房间畅聊，也可以开辟私人房间，与亲友聊天</span
            >
          </div>
        </div>
      </div>
      <div class="right-login-form">
        <div class="form-wrapper">
          <div class="menu">
            <div class="login">
              <div class="card">
                <q-icon
                  class="avatar"
                  :name="userAvatar"
                  @click="openCard"
                />
                <!-- <q-dialog class="dialog" v-model="fixed"> -->
                <q-dialog class="dialog" v-model="fixed">
                  <q-card class="pop-item">
                    <q-card-section style="max-height: 50vh" class="scroll">
                      <div class="avatar-item" v-for="n in avatarSet" :key="n">
                        <q-btn class="icon-btn" @click="changeAvatar(n)"  unelevated round padding="0px" size="2rem"
                          ><q-icon :name="n"></q-icon
                        ></q-btn>
                      </div>
                    </q-card-section>
                  </q-card>
                </q-dialog>
              </div>
              <div class="room-situation">
                <div class="situation-title">
                  <q-btn class="mode-btn" align="between" @click="privateOrPublic" :icon="isPublic?'lock_open':'lock'" :label="roomSituation" unelevated>
                  </q-btn>
                </div>
                
              </div>
              <div class="input-items" v-if="!isPublic">
                <span class="input-tips">Room Name</span>
                <q-input class="inputs" v-model="roomName" color="black">
                  <template #append>
                    <q-btn @click="randomRoomName" unelevated round>
                      <q-icon class="refresh-btn" name="refreshing"></q-icon>
                    </q-btn>
                  </template>
                </q-input>
              </div>
              <div class="input-items">
                <span class="input-tips">Nick Name</span>
                <q-input class="inputs" v-model="nickName" color="black">
                  <template #append>
                    <q-btn @click="randomNickName" unelevated round>
                      <q-icon class="refresh-btn" name="refreshing"></q-icon>
                    </q-btn>
                  </template>
                </q-input>
              </div>
              <q-btn class="btn" @click="login" :disabled="validated"
                >login
                <!-- <q-icon name="login"></q-icon> -->
              </q-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 
  <div class="menu">
    <div class="login">
      <div>
        <q-btn @click="privateOrPublic">{{roomSituation}}</q-btn>
      </div>
      <div>
        <q-icon
          :name="userAvatar"
          style="height: 50px; width: 50px; font-size: 56px;"
        />
      <q-btn label="Avatar" @click="openCard" />
      <q-dialog v-model="fixed">
        <q-card>
          <q-card-section>
            <div>Avatar</div>
          </q-card-section>
          <q-separator />
          <q-card-section style="max-height: 50vh" class="scroll">
            <div v-for="n in avatarSet" :key="n">
              <q-btn @click="changeAvatar(n)"><q-icon :name="n"></q-icon></q-btn>
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>    
      </div>
      <div v-if="!isPublic">
        <q-input v-model="roomName"></q-input>
        <q-btn @click="randomRoomName">RoomName</q-btn>      
      </div>
      <q-input v-model="nickName"></q-input>
      <q-btn @click="randomNickName">NickName</q-btn>
      <br />
      <q-btn @click="login" :disabled="validated">login</q-btn>
    </div>

</div> -->
</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, onUnmounted } from "vue";
import { useStore } from "vuex";
import socket from "@/utils/socket";
import {
  uniqueNamesGenerator,
  adjectives,
  countries,
  starWars,
} from "unique-names-generator";

let avatarSet = ref();
let roomName = ref("");
let nickName = ref("");
let userAvatar = ref("");
const state = useStore();
let userOnline = ref();
const router = useRouter();
let fixed = ref(false);
let roomSituation = ref("Public");
let isPublic = ref(true);
let userData=ref();

const login = () => {
  if (roomName.value && nickName.value) {
    const info = {
      room: roomName.value,
      nickName: nickName.value,
      avatar: userAvatar.value,
    };
    state.userInfo=info;
    socket.emit(
      "checkName",
      { room: roomName.value, nickName: nickName.value },
      (isUsed) => {
        if (isUsed) {
          alert("重复");
        } else {
          // socket.emit("login", info, (data) => {
          //   state.userInfo = data;
          // });
          socket.emit("login", info)
          socket.emit("getOnlineUser", (data) => {
            userOnline.value = data;
          });
          router.push({ path: "/room" });
        }
      }
    );
  } else {
    if (!nickName.value) {
      alert("Nickname Miss!!!");
    }
    if (!roomName.value) {
      alert("Roomname Miss!!!");
    }
  }
};

let randomRoomNameConfig = {
  dictionaries: [adjectives, countries],
  separator: " ",
  length: 2,
};

let randomRoomName = () => {
  let name = uniqueNamesGenerator(randomRoomNameConfig);
  roomName.value = name.slice(0, 1).toUpperCase() + name.slice(1);
};

const randomNickNameConfig = {
  dictionaries: [adjectives, starWars],
  separator: "-",
  length: 2,
};

let randomNickName = () => {
  const name = uniqueNamesGenerator(randomNickNameConfig);
  let nickname = name.slice(0, 1).toUpperCase() + name.slice(1);
  nickName.value = nickname;
};

let changeAvatar = (n) => {
  userAvatar.value = n;
  fixed.value = false;
};

let openCard = () => {
  fixed.value = true;
  avatarSet.value = ["account_circle", "pets", "flag", "block","tram","star","star_half","star_outline"];
};

let privateOrPublic = () => {
  if (roomSituation.value == "Public") {
    roomSituation.value = "Private";
    roomName.value = "";
    isPublic.value = false;
  } else {
    roomSituation.value = "Public";
    roomName.value = "Public";
    isPublic.value = true;
  }
};

onMounted(() => {
  userAvatar.value = "account_circle";
  roomName.value = "Public";
});

onUnmounted(() => {
  window.addEventListener("popstate", () => {
    router.push({ path: "/" });
  });
});
</script>


<style lang="scss">
* {
  padding: 0;
  margin: 0;
  font-family: "Aguazyuan", Courier, monospace;
}

.content {
  width: 100vw;
  height: 100vh;
  // background-color: rgb(150, 150, 150);
  background: url("@/assets/2.jpg") no-repeat;
  background-size: cover;
  // background-image: linear-gradient(
  //   to top,
  //   #1a4d78,
  //   #205f89,
  //   #27719a,
  //   #3183aa,
  //   #3c96b9,
  //   #47a6c0,
  //   #56b5c7,
  //   #68c4cc,
  //   #83d2cb,
  //   #a0dfca,
  //   #beebcd,
  //   #dbf6d3
  // );
  position: relative;
  .login-wrapper {
    width: 80vw;
    height: 80vh;
    background-color: #fff;
    border-radius: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    .left-img {
      border-radius: 40px;
      flex: 1;
      background: url("@/assets/1.png") no-repeat;
      background-size: cover;
      position: relative;
      .glass {
        width: 70%;
        height: 75%;
        padding: 20px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgb(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        border-radius: 15px;
        .tips {
          .title {
            width: 100%;
            font-weight: 600;
            font-size: 15px;
            text-align: center;
            padding: 10px;
            overflow: hidden;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 15px;
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
          }
          h1 {
            margin: 5px 0;
            font-size: 18px;
            font-weight: 100;
          }
          span {
            margin: 10px 0;
            display: block;
            font-weight: 100;
          }
        }
      }
    }
    .right-login-form {
      flex: 1;
      position: relative;
      .form-wrapper {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -45%);
        .input-items {
          margin: 20px 0;
          .input-tips {
            display: inline-block;
            font-weight: 600;
            font-size: 20px;
            margin: 5px 0;
          }
          .inputs {
            width: 100%;
            height: 50px;
          }
        }
        .btn {
          width: 100%;
          height: 50px;
          background-color: black;
          border: 0;
          border-radius: 10px;
          color: white;
          font-size: 25px;
          font-weight: 600;
          margin: 20px 0;
        }
        .avatar{
          text-align: center;
          width: 100%;
          font-size:75px;
          margin: 20px 5px;
        }
        .room-situation{
          width: 100%;
          text-align: center;
          .situation-title{
            font-size: 15px;
            font-weight: 200;
          }
        }
      }
    }
  }
}

.pop-item{
  height: 50%;
  width: 45%;
  border-radius: 40px;
  .avatar-item{
    display: inline-block;
    .icon-btn{
      margin: 20% 20%;
    }
  }
}

@media(max-width:960px){
  .login-wrapper{
    padding:20px;
    .left-img{
      display: none;
    }
    .right-login-form{
      padding: 20px;
    }
  }
}
</style>